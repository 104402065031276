"use client";
import Link from "next/link";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
  Box,
} from "@mui/material";

import { setURL } from "utils/outclicks";
import { SearchParamsHook } from "lib/utm";
import { Add, Remove } from "@mui/icons-material";
import config from "config/env";
import { useState } from "react";
import Image from "next/image";

const Logo = styled(Grid)`
  display: flex;
  justify-content: top;
  flex-direction: column;
  align-items: center;
  background-color: #2a5993;
  color: #fff;
  padding: 40px 12px 10px !important;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 12px !important;
  }
`;

const FooterText = styled(Typography)`
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  padding: 4px 0px;
  margin-bottom: 4px;
  letter-spacing: 0.414px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    text-align: left !important;
  }
`;

const FooterTitle = styled(Typography)`
  text-transform: uppercase;
  font-family: Staaliches;
  color: #fff;
  font-size: 22px;
  display: block;
  padding: 5px 0px;
  font-weight: 600;
  text-align: center !important;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    text-align: left !important;
  }
`;

const FooterLinks1 = [
  {
    text: "Barefoot",
    link: `#`,
    className: "C012-menu-barefoot",
  },
  {
    text: "Men",
    link: `#`,
    className: "C012-menu-men",
  },
  {
    text: "Women",
    link: `#`,
    className: "C012-menu-women",
  },
  {
    text: "Kids",
    link: `#`,
    className: "C012-menu-kids",
  },
  {
    text: "Accessories",
    link: `#`,
    className: "C012-menu-accessories",
  },
  {
    text: "Sports",
    link: `#`,
    className: "C012-menu-sports",
  },
  {
    text: "Shop By Brand",
    link: `#`,
    className: "C012-menu-brand",
  },
  {
    text: "Sale Corner",
    link: `/collections/66d9c591a19786a88762ad3d`,
    className: "C012-menu-sale-corner",
  },
];

const FooterLinks2 = [
  {
    text: "Vibram",
    link: `#`,
    className: "C012-menu-vibram",
  },
  {
    text: "Running Shoes",
    link: `/collections/66d9c591a19786a88762adab`,
    className: "C012-menu-running-shoes",
  },
  {
    text: "Accessories",
    link: `#`,
    className: "C012-menu-accessories",
  },
  {
    text: "Cycling Apparel",
    link: `#`,
    className: "C012-menu-cycling-apparel",
  },
];

const MobileFooterDiv = styled.div`
  background-color: #2a5993;
  padding: 10px;
  @media only screen and (min-width: 751px) {
    display: none;
  }
`;

const DesktopFooterDiv = styled.div`
  background-color: #2a5993;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const FooterLinks3 = [
  {
    text: "Vibram V-Run",
    link: `#`,
    className: "C012-menu-v-run",
  },
  {
    text: "Vibram V-Train",
    link: `#`,
    className: "C012-menu-v-train",
  },
  {
    text: "Vibram Kso Evo",
    link: `#`,
    className: "C012-menu-kso-evo",
  },
  {
    text: "Vibram V-Soul",
    link: `#`,
    className: "C012-menu-contact",
  },
];

const FooterLinks4 = [
  {
    text: "About Us",
    link: `${config.brandURL}/pages/about-gambol`,
    className: "C012-menu-about-us",
  },
  {
    text: "Contact Us",
    link: `${config.brandURL}/pages/contact-us`,
    className: "C012-menu-contact",
  },
  {
    text: "Shipping & Return Policy",
    link: `${config.brandURL}/shipping-and-return-policy`,
    className: "C012-menu-shipping",
  },
  {
    text: "Track Your Order",
    link: `${config.brandURL}/pages/track-order`,
    className: "C012-menu-contact",
  },
  {
    text: "FAQ",
    link: `${config.brandURL}/pages/faq`,
    className: "C012-menu-contact",
  },
  {
    text: "Privacy Policy",
    link: `${config.brandURL}/pages/privacy-policy`,
    className: "C012-menu-contact",
  },
];

export default function Footer() {
  const [expanded, setExpanded] = useState(false);
  const queryParams = SearchParamsHook();

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <MobileFooterDiv className="C012">
        <Accordion
          disableGutters
          sx={{
            background: "#2a5993",
            "&::before": { backgroundColor: "#fff" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "left" },
          }}
          onChange={handleExpansion}
          className="C012-menu-0"
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <Remove
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-minus"
                />
              ) : (
                <Add
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-add"
                />
              )
            }
            aria-controls="panel2-content"
            id="panel2-header"
            className="C012-menu-0"
            sx={{
              background: "#2a5993",
              textAlign: "left",
              justifyContent: "left",
            }}
          >
            <FooterTitle className="C012-menu-0">SHOP</FooterTitle>
          </AccordionSummary>
          <AccordionDetails
            className="C012-menu-0"
            sx={{ background: "#2a5993" }}
          >
            {FooterLinks1.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-key-${idx}`}
                className={`C012-menu-0-item-${idx}`}
              >
                <FooterText className={`C012-menu-0-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            background: "#2a5993",
            "&::before": { backgroundColor: "#fff" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "left" },
          }}
          className="C012-menu-1"
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <Remove
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-minus"
                />
              ) : (
                <Add
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-add"
                />
              )
            }
            aria-controls="panel3-content"
            id="panel3-header"
            className="C012-menu-1"
          >
            <FooterTitle className="C012-menu-1">BEST SELLERS</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-1">
            {FooterLinks2.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section2-key-${idx}`}
                className={`C012-menu-1-item-${idx}`}
              >
                <FooterText className={`C012-menu-0-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            background: "#2a5993",
            "&::before": { backgroundColor: "#fff" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "left" },
          }}
          className="C012-menu-2"
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <Remove
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-minus"
                />
              ) : (
                <Add
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-add"
                />
              )
            }
            aria-controls="panel1-content"
            id="panel1-header"
            className="C012-menu-2"
            sx={{ textAlign: "left" }}
          >
            <FooterTitle className="C012-menu-2">POPULAR VIBRAM</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-2">
            {FooterLinks3.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section3-key-${idx}`}
                className={`C012-menu-2-item-${idx}`}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            background: "#2a5993",
            "&::before": { backgroundColor: "#fff" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "left" },
          }}
          className="C012-menu-3"
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <Remove
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-minus"
                />
              ) : (
                <Add
                  fontSize="small"
                  sx={{ color: "#fff", fontSize: "1rem" }}
                  className="C012-Accordion-expand-add"
                />
              )
            }
            aria-controls="panel1-content"
            id="panel1-header"
            className="C012-menu-3"
            sx={{ textAlign: "left" }}
          >
            <FooterTitle className="C012-menu-3">SUPPORT</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-3">
            {FooterLinks4.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section4-key-${idx}`}
                className={`C012-menu-3-item-${idx}`}
              >
                <FooterText className={`C012-menu-3-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Divider sx={{ background: "white" }} />
        <Box
          display="flex"
          justifyContent="space-between"
          p="20px 17px"
          mt="15px"
          className="C012-container"
        >
          <FooterText fontSize="12px" className="C012-terms">
            Terms & conditions
          </FooterText>
          <FooterText fontSize="12px" className="C012-privacy">
            Privacy Policy
          </FooterText>
        </Box>
      </MobileFooterDiv>
      <Divider sx={{ background: "white", width: "100%" }} />
      <MobileFooterDiv
        style={{ padding: "0 1.05rem" }}
        className="C012-details"
      >
        <Box
          minHeight="50px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m="0px"
          p="0px 7px"
          className="C012-details"
        >
          <Image
            alt="logo"
            src="/static/gambol_white.png"
            height={23}
            width={82}
            className="C012-logo"
          />
          <FooterText
            fontSize="11px"
            m="0px !important"
            className="C012-copyright-year"
            letterSpacing="0.05em !important"
            lineHeight={1.2}
          >
            © GAMBOL 2024 All rights reserved
          </FooterText>
        </Box>
      </MobileFooterDiv>

      <DesktopFooterDiv className="C012">
        <Divider />
        <Grid container className="C012">
          <Logo item xs={3} className="C012-menu">
            <FooterTitle className="C012-menu-policy">SHOP</FooterTitle>
            {FooterLinks1.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section5-key-${idx}`}
                className={`C012-menu-4-item-${idx}`}
              >
                <FooterText className={`C012-menu-4-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </Logo>
          <Logo item xs={3} className="C012-menu">
            <FooterTitle className="C012-menu-order">BEST SELLERS</FooterTitle>
            {FooterLinks2.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section6-key-${idx}`}
                className={`C012-menu-5-item-${idx}`}
              >
                <FooterText className={`C012-menu-5-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </Logo>
          <Logo item xs={3} className="C012-menu">
            <FooterTitle className="C012-menu-info">POPULAR VIBRAM</FooterTitle>
            {FooterLinks3.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section7-key-${idx}`}
                className={`C012-menu-6-item-${idx}`}
              >
                <FooterText className={`C012-menu-6-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </Logo>
          <Logo item xs={3} className="C012-menu">
            <FooterTitle className="C012-menu-info">SUPPORT</FooterTitle>
            {FooterLinks4.map((item, idx) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={`footer-section8-key-${idx}`}
                className={`C012-menu-7-item-${idx}`}
              >
                <FooterText className={`C012-menu-7-item-${idx}`}>
                  {item.text}
                </FooterText>
              </Link>
            ))}
          </Logo>
        </Grid>
        <Divider sx={{ background: "white" }} />
        <Grid container padding="2rem" className="C012-details">
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="C012-logo"
          >
            <Image
              alt="logo"
              src="/static/gambol_white.png"
              height={46}
              width={160}
              className="C012-logo"
            />
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row" }}>
            <Box p="20px 17px" textAlign="center">
              <FooterText className="C012-copyright-year">
                © GAMBOL 2024 All rights reserved
              </FooterText>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "left",
              flexDirection: "row",
            }}
            className="C012-details"
          >
            <FooterText
              fontSize="12px"
              textAlign="left"
              mr={5}
              className="C012-terms"
            >
              Terms & conditions
            </FooterText>
            <FooterText
              fontSize="12px"
              textAlign="left"
              className="C012-privacy"
            >
              Privacy Policy
            </FooterText>
          </Grid>
        </Grid>
      </DesktopFooterDiv>
    </>
  );
}
