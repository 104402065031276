import styled from "@emotion/styled";
import { Box, Fade, Menu, Typography } from "@mui/material";
import Link from "next/link";
import { Fragment } from "react";

const NoChildBox = styled(Box)`
  padding: 5px 0;
  min-width: 200px;
  max-width: 270px;
`;

const ChildHeader = styled(Typography)`
  font-weight: 300;
  color: #2a5993;
  letter-spacing: 1px;
  font-family: Staaliches;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SubChildText = styled(Typography)`
  color: #2a5993;
  letter-spacing: 1px;
  font-family: Staaliches;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  background: #fff;
  line-height: 1.5;
  margin-top: 10px;
  &:hover {
    color: #2a5993;
  }
`;

const NoChildTypography = styled(Typography)`
  color: #2a5993;
  letter-spacing: 1px;
  font-family: Staaliches;
  font-weight: 400;
  font-style: normal;
  background: #fff;
  line-height: 1.5;
  display: flex;
  padding-left: 20px;
  padding-right: 30px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;
  text-transform: capitalize;
  &:hover {
    color: #2a5993;
  }
`;

export default function DesktopMenu({
  item,
  handleClose,
  open,
  labelId,
  anchorEl,
  parentIdx,
}) {
  return (
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      MenuListProps={{
        "aria-labelledby": labelId,
      }}
      className={`C003-${parentIdx}-DesktopMenu`}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          width:
            item[0] && item[0].children && item[0].children.length
              ? "100%"
              : "fit-content",
          maxHeight: "400px",
          boxShadow: "none",
          padding: "15px 0px",
          overflowY: "auto",
          zIndex: "99999999999",
          borderRadius: "0 0 3px 3px",
          borderLeft: "1px solid #e1e3e4",
          borderRight: "1px solid #e1e3e4",
          borderBottom: "1px solid #e1e3e4",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={
          item[0] && item[0].children && item[0].children.length
            ? "row"
            : "column"
        }
        justifyContent="center"
        alignItems="flex-start"
        padding="20px 0px 0px"
        key={item.value}
      >
        {item.map(({ children, value, link }, idx) => {
          if (children && children.length) {
            return (
              <Fragment key={idx}>
                <Box
                  display="flex"
                  flexDirection="column"
                  key={`C003-${parentIdx}-DesktopMenu-${idx}`}
                  margin="5px"
                  marginRight="80px"
                  className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                >
                  <>
                    <Link
                      href={link}
                      className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                    >
                      <ChildHeader
                        className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                      >
                        {value}
                      </ChildHeader>
                    </Link>
                    {children.map((subChildItem, subChildIdx) => {
                      return (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          key={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                          className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                        >
                          <Link
                            href={subChildItem.link}
                            className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                          >
                            <SubChildText
                              onClick={handleClose}
                              className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                            >
                              {subChildItem.value}
                            </SubChildText>
                          </Link>
                        </Box>
                      );
                    })}
                  </>
                </Box>
              </Fragment>
            );
          }
          return (
            <NoChildBox
              key={value}
              className={`C003-${parentIdx}-DesktopMenu-${idx}`}
            >
              <Link
                href={link}
                style={{ color: "#222" }}
                className={`C003-${parentIdx}-DesktopMenu-${idx}`}
              >
                <NoChildTypography
                  className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                >
                  {value}
                </NoChildTypography>
              </Link>
            </NoChildBox>
          );
        })}
      </Box>
    </Menu>
  );
}
