export const MenuItems = [
  {
    value: "BAREFOOT",
    link: "#",
    children: [
      {
        value: "VIBRAM FIVE FINGERS",
        link: "/collections/66d9c591a19786a88762adc7",
        children: [
          {
            value: "Vibram for him",
            link: `/collections/66d9c591a19786a88762adc8`,
          },
          {
            value: "Vibram for her",
            link: `/collections/66d9c591a19786a88762adc6`,
          },
          {
            value: "Toe socks",
            link: `/collections/66d9c591a19786a88762adc0`,
          },
        ],
      },
      {
        value: "Vibram One Quarter (NEW)",
        link: "/collections/66d9c591a19786a88762adca",
      },
      {
        value: "Vibram Furoshiki",
        link: "/collections/66d9c591a19786a88762ad72",
      },
      {
        value: "Skinners",
        link: "/collections/66d9c591a19786a88762adb1",
      },
      {
        value: "Luna Sandal",
        link: "/collections/66d9c591a19786a88762ad98",
      },
      {
        value: "All Barefoot Shoes",
        link: "/collections/66d9c591a19786a88762ad47",
      },
    ],
  },
  {
    value: "MEN",
    link: "#",
    children: [
      {
        value: "BAREFOOT",
        link: "#",
        children: [
          {
            value: "Vibram",
            link: `/collections/66d9c591a19786a88762adc6`,
          },
          {
            value: "Skinner",
            link: `/collections/66d9c591a19786a88762adb1`,
          },
          {
            value: "Furoshiki",
            link: `/collections/66d9c591a19786a88762ad72`,
          },
          {
            value: "Toe Socks",
            link: `/collections/66d9c591a19786a88762adc0`,
          },
        ],
      },
      {
        value: "SHOES",
        link: "#",
        children: [
          {
            value: "Running",
            link: `/collections/66d9c591a19786a88762adab`,
          },
          {
            value: "Football",
            link: `/collections/66d9c591a19786a88762ad6e`,
          },
          {
            value: "Cricket",
            link: `/collections/66d9c591a19786a88762ad54`,
          },
          {
            value: "Tennis",
            link: `/collections/66d9c591a19786a88762adbf`,
          },
          {
            value: "Badminton",
            link: `/collections/66d9c591a19786a88762ad46`,
          },
          {
            value: "Golf",
            link: `/collections/66d9c591a19786a88762ad7d`,
          },
          {
            value: "Flip-Flop",
            link: `/collections/66d9c591a19786a88762ad69`,
          },
          {
            value: "Lifestyle",
            link: `#`,
          },
          {
            value: "Walking",
            link: `/collections/66d9c591a19786a88762add1`,
          },
          {
            value: "Basketball",
            link: `/collections/66d9c591a19786a88762ad49`,
          },
          {
            value: "Sandals",
            link: `/collections/66d9c591a19786a88762adae`,
          },
          {
            value: "Discounted",
            link: `/collections/66d9c591a19786a88762ad5f`,
          },
          {
            value: "Gym and Training",
            link: `/collections/66d9c591a19786a88762adc1`,
          },
        ],
      },
      {
        value: "CLOTHING",
        link: "#",
        children: [
          {
            value: "T-shirt",
            link: `/collections/66d9c591a19786a88762ad9d`,
          },
          {
            value: "Track Pant",
            link: `/collections/66d9c591a19786a88762ad9b`,
          },
          {
            value: "Shorts",
            link: `/collections/66d9c591a19786a88762ad9c`,
          },
          {
            value: "Jacket",
            link: `/collections/66d9c591a19786a88762ad9a`,
          },
        ],
      },
    ],
  },
  {
    value: "WOMEN",
    link: "#",
    children: [
      {
        value: "BAREFOOT",
        link: "#",
        children: [
          {
            value: "Vibram",
            link: `/collections/66d9c591a19786a88762adc6`,
          },
          {
            value: "Skinner",
            link: `/collections/66d9c591a19786a88762adb1`,
          },
          {
            value: "Luna",
            link: `/collections/66d9c591a19786a88762ad98`,
          },
          {
            value: "Furoshiki",
            link: `/collections/66d9c591a19786a88762ad72`,
          },
          {
            value: "Toe Socks",
            link: `/collections/66d9c591a19786a88762adc0`,
          },
        ],
      },
      {
        value: "SHOES",
        link: "#",
        children: [
          {
            value: "Running",
            link: `/collections/66d9c591a19786a88762adac`,
          },
          {
            value: "Football",
            link: `/collections/66d9c591a19786a88762ad6e`,
          },
          {
            value: "Tennis",
            link: `/collections/66d9c591a19786a88762adbf`,
          },
          {
            value: "Badminton",
            link: `#`,
          },
          {
            value: "Golf",
            link: `#`,
          },
          {
            value: "Flip-Flop",
            link: `/collections/66d9c591a19786a88762ad68`,
          },
          {
            value: "Lifestyle Shoes",
            link: `/collections/66d9c591a19786a88762add4`,
          },
          {
            value: "Walking Shoes",
            link: `/collections/66d9c591a19786a88762add0`,
          },
          {
            value: "Sandals",
            link: `#`,
          },
          {
            value: "Discounted Shoes",
            link: `#`,
          },
          {
            value: "Gym and Training",
            link: `#`,
          },
        ],
      },
      {
        value: "CLOTHING",
        link: "#",
        children: [
          {
            value: "T-shirt",
            link: `/collections/66d9c591a19786a88762add7`,
          },
          {
            value: "Track Pant",
            link: `/collections/66d9c591a19786a88762add8`,
          },
          {
            value: "Shorts",
            link: `/collections/66d9c591a19786a88762add5`,
          },
          {
            value: "Jacket",
            link: `/collections/66d9c591a19786a88762add3`,
          },
          {
            value: "Sports Bra",
            link: `/collections/66d9c591a19786a88762adb3`,
          },
        ],
      },
      {
        value: "ACCESSORIES",
        link: "#",
        children: [
          {
            value: "Gym Gloves",
            link: `#`,
          },
          {
            value: "Caps",
            link: `#`,
          },
          {
            value: "Sunglasses",
            link: `#`,
          },
          {
            value: "Running Gears",
            link: `#`,
          },
          {
            value: "Backpacks",
            link: `#`,
          },
          {
            value: "Socks",
            link: `#`,
          },
        ],
      },
    ],
  },
  {
    value: "KIDS",
    link: "",
    children: [
      {
        value: "SHOES(0 TO 3 YEARS)",
        link: "/collections/66d9c591a19786a88762ad8d",
      },
      {
        value: "SHOES(4 TO 7 YEARS)",
        link: "/collections/66d9c591a19786a88762ad8f",
      },
      {
        value: "SHOES(8 TO 12 YEARS)",
        link: "/collections/66d9c591a19786a88762ad91",
      },
      {
        value: "APPARELS(0 TO 3 YEARS)",
        link: "/collections/66d9c591a19786a88762ad8c",
      },
      {
        value: "APPARELS(4 TO 7 YEARS)",
        link: "/collections/66d9c591a19786a88762ad8e",
      },
      {
        value: "APPARELS(8 TO 12 YEARS)",
        link: "/collections/66d9c591a19786a88762ad90",
      },
      {
        value: "KIDS SWIMMING COSTUME",
        link: "/collections/66d9c591a19786a88762ad8a",
      },
    ],
  },
  {
    value: "ACCESSORIES",
    link: "",
    children: [
      {
        value: "ARM SLEEVES",
        link: "/collections/66d9c591a19786a88762ad42",
        child: true,
      },
      {
        value: "SOCKS",
        link: "/collections/66d9c591a19786a88762adb2",
      },
      {
        value: "CAPS",
        link: "/collections/66d9c591a19786a88762ad4f",
      },
      {
        value: "GYM GLOVES",
        link: "/collections/66d9c591a19786a88762ad81",
      },
      {
        value: "SUNGLASSES",
        link: "/collections/66d9c591a19786a88762ada2",
      },
      {
        value: "SIPPERS & BOTTLES",
        link: "/collections/66d9c591a19786a88762adb7",
      },
      {
        value: "HEADBAND & WRISTBAND",
        link: "/collections/66d9c591a19786a88762ad83",
      },
      {
        value: "SPORTY BAGS",
        link: "#",
        children: [
          {
            value: "Backpack",
            link: `/collections/66d9c591a19786a88762ad44`,
          },
          {
            value: "Gym Bag",
            link: `/collections/66d9c591a19786a88762ad7e`,
          },
          {
            value: "Kids Bag",
            link: `/collections/66d9c591a19786a88762ad94`,
          },
          {
            value: "Racksacks",
            link: `/collections/66d9c591a19786a88762ada8`,
          },
          {
            value: "Travel Bag",
            link: `/collections/66d9c591a19786a88762adc2`,
          },
          {
            value: "Hydration Bag",
            link: `/collections/66d9c591a19786a88762ad85`,
          },
        ],
      },
    ],
  },
  {
    value: "SPORTS",
    link: "",
    children: [
      {
        value: "SWIMMING",
        link: "#",
        children: [
          {
            value: "Mens Swimming Costume",
            link: `/collections/66d9c591a19786a88762ad99`,
          },
          {
            value: "Womens Swimming Costume",
            link: `/collections/66d9c591a19786a88762add6`,
          },
          {
            value: "Kids Swimming Costume",
            link: `/collections/66d9c591a19786a88762ad8a`,
          },
          {
            value: "Swimming Goggles",
            link: `/collections/66d9c591a19786a88762adbc`,
          },
          {
            value: "Swimming Caps",
            link: `/collections/66d9c591a19786a88762adba`,
          },
          {
            value: "Swimming Accessories",
            link: `/collections/66d9c591a19786a88762adb8`,
          },
        ],
      },
      {
        value: "CRICKET",
        link: "#",
        children: [
          {
            value: "Cricket Shoes",
            link: `/collections/66d9c591a19786a88762ad54`,
          },
          {
            value: "Cricket Bats",
            link: `/collections/66d9c591a19786a88762ad53`,
          },
          {
            value: "IPL Jerseys",
            link: `/collections/66d9c591a19786a88762ad88`,
          },
        ],
      },
      {
        value: "FOOTBALL",
        link: "#",
        children: [
          {
            value: "Football",
            link: `/collections/66d9c591a19786a88762ad6b`,
          },
          {
            value: "Football Shoes",
            link: `/collections/66d9c591a19786a88762ad6e`,
          },
          {
            value: "Football Stockings",
            link: `/collections/66d9c591a19786a88762ad6f`,
          },
          {
            value: "Goal Keeper Gloves",
            link: `/collections/66d9c591a19786a88762ad77`,
          },
          {
            value: "Football Jerseys",
            link: `/collections/66d9c591a19786a88762ad6c`,
          },
          {
            value: "Football Accessories",
            link: `/collections/66d9c591a19786a88762ad6f`,
          },
        ],
      },
      {
        value: "TENNIS",
        link: "#",
        children: [
          {
            value: "Tennis Shoes",
            link: `/collections/66d9c591a19786a88762adbf`,
          },
          {
            value: "Tennis Racket",
            link: `/collections/66d9c591a19786a88762adbe`,
          },
        ],
      },
      {
        value: "BADMINTON",
        link: "#",
        children: [
          {
            value: "Badminton Shoes",
            link: `/collections/66d9c591a19786a88762ad46`,
          },
          {
            value: "Badminton Racket",
            link: `/collections/66d9c591a19786a88762ad45`,
          },
        ],
      },
      {
        value: "CHESS",
        link: "/collections/66d9c591a19786a88762ad74",
      },
      {
        value: "BASKETBALL",
        link: "/collections/66d9c591a19786a88762ad48",
      },
      {
        value: "GOLF",
        link: "#",
        children: [
          {
            value: "Golf Shoes",
            link: `/collections/66d9c591a19786a88762ad7d`,
          },
          {
            value: "Golf Kits",
            link: `/collections/66d9c591a19786a88762ad7c`,
          },
          {
            value: "Golf Apparels",
            link: `/collections/66d9c591a19786a88762ad7a`,
          },
          {
            value: "Golf Accessories",
            link: `/collections/66d9c591a19786a88762ad79`,
          },
        ],
      },
    ],
  },
  {
    value: "SHOP BY BRAND",
    link: "",
    children: [
      {
        value: "CASTELLI",
        link: "/collections/66d9c591a19786a88762ad51",
      },
      {
        value: "CEP",
        link: "/collections/66d9c591a19786a88762ad52",
      },
      {
        value: "SKINNERS",
        link: "/collections/66d9c591a19786a88762adb1",
      },
      {
        value: "ADVENTURE WORX",
        link: "/collections/66d9c591a19786a88762ad3c",
      },
      {
        value: "ASICS",
        link: "/collections/66d9c591a19786a88762ad43",
      },
      {
        value: "CAMELBAK",
        link: "/collections/66d9c591a19786a88762ad4e",
      },
      {
        value: "ADIDAS",
        link: "/collections/66d9c591a19786a88762ad39",
      },
      {
        value: "SKECHERS",
        link: "/collections/66d9c591a19786a88762adb0",
      },
      {
        value: "VIBRAM",
        link: "/collections/66d9c591a19786a88762adc7",
      },
      {
        value: "FOX 40",
        link: "/collections/66d9c591a19786a88762ad70",
      },
      {
        value: "BLACKROLL",
        link: "/collections/66d9c591a19786a88762ad4d",
      },
      {
        value: "LUNA",
        link: "/collections/66d9c591a19786a88762ad98",
      },
      {
        value: "PACKET",
        link: "/collections/66d9c591a19786a88762ada5",
      },
      {
        value: "SPEEDO SWIMMING",
        link: "/collections/66d9c591a19786a88762adb8",
      },
      {
        value: "ARETTO",
        link: "/collections/66d9c591a19786a88762ad41",
      },
    ],
  },
  {
    value: "SALE CORNER",
    link: "/collections/66d9c591a19786a88762ad3d",
    children: [],
  },
];
